import * as APIt from '../../API';
import {
  Alert,
  Box,
  Button,
  Container,
  FormField,
  Grid,
  Link,
  Select,
  SelectProps,
  SpaceBetween,
  Spinner,
} from '@amzn/awsui-components-react';
import { Privileges, fetchUserSitePrivs } from 'src/utils/UserSitesUtils';
import React, { useEffect, useRef, useState } from 'react';
import { URLS, UserActionNames } from 'src/constants/Constants';
import {
  selectEmployeeId,
  selectPhySecMember,
  selectSideTeam,
  selectSigAdmin,
  selectUserPrefs,
  selectUserSites,
  selectUsername,
  setUserPrefs,
  setUserSelectedSite,
  setUserSelectedSitePrivs,
} from 'src/stores/slices/userSlice';
import {
  setInputsSearchMaskingGroup,
  setReadersAlarmsSearchMaskingGroup,
  setReadersModesSearchMaskingGroup,
} from 'src/stores/slices/devicesSlice';
import { useSelector, useDispatch } from 'react-redux';
import { createUserAction } from 'src/utils/UserActionsUtils';
import { debug } from 'src/utils/commonUtils';
import { updateUserPrefs } from 'src/utils/UserPrefsUtils';
import { useBundle } from '@amzn/react-arb-tools';

export default function SelectSite(props: any) {
  debug(`SelectSite(): props is ${JSON.stringify(props)}`);

  const [bundle, isBundleLoading] = useBundle('components.navigation.SelectSite');
  const dispatch = useDispatch();

  const employeeId = useSelector(selectEmployeeId) as number;
  const phySecMember = useSelector(selectPhySecMember) as boolean;
  const sideTeam = useSelector(selectSideTeam) as boolean;
  const sigAdmin = useSelector(selectSigAdmin) as boolean;
  const username = useSelector(selectUsername) as string;
  const userPrefs = useSelector(selectUserPrefs) as APIt.UserPrefs;
  const userSites = useSelector(selectUserSites) as APIt.UserPrefSite[];

  const [siteInvalidAlertVisible, setSiteInvalidAlertVisible] = useState<boolean>(false);
  const [siteRequiredAlertVisible, setSiteRequiredAlertVisible] = useState<boolean>(false);
  const [selectedSiteName, setSelectedSiteName] = React.useState<string | undefined>(props.selectedSite?.sitename);
  debug(`SelectSite(): selectedSiteName is ${selectedSiteName}`);

  const getSiteOptions = (): SelectProps.Options => {
    debug(`SelectSite(): getSiteOptions() userSites: ${JSON.stringify(userSites)}`);
    const validSites = userSites.filter(s => s.siteRegion && s.siteRegion.toLowerCase() !== 'unknown');
    return validSites.map(s => {
      return {label: s.sitename, value: s.sitename};
    }).sort((c, p) => c.label! < p.label! ? -1 : 1);
  }

  const siteFieldOnChangeHandler = (detail: SelectProps.ChangeDetail) => {
    setSelectedSiteName(detail.selectedOption.label);
  };

  const siteSelected = async () => {
    debug(`SelectSite(): siteSelected() selectedSiteName is ${selectedSiteName}`);
    const selectedSite = userSites.find(s => s.sitename === selectedSiteName);
    debug(`SelectSite(): siteSelected() selectedSite is ${JSON.stringify(selectedSite)}`);
    if (!selectedSite || !selectedSite.sitename) {
      setSiteRequiredAlertVisible(true);
      return;
    }
    createUserAction(
      {
        actionName: UserActionNames.SelectSite,
        username: username,
        parameters: JSON.stringify(
          {
            selectedSite: selectedSiteName,
            employeeId: employeeId
          })
      });

    debug(`SelectSite(): siteSelected() userPrefs is ${JSON.stringify(userPrefs)}`);
    let newUserPrefs = {
      ...userPrefs,
      lastSite: selectedSite
    };
    debug(`SelectSite(): siteSelected() newUserPrefs is ${JSON.stringify(newUserPrefs)}`);
    dispatch(setUserPrefs(newUserPrefs))
    updateUserPrefs(newUserPrefs);

    dispatch(setUserSelectedSitePrivs(undefined));
    dispatch(setInputsSearchMaskingGroup(undefined));
    dispatch(setReadersAlarmsSearchMaskingGroup(undefined));
    dispatch(setReadersModesSearchMaskingGroup(undefined));
    
    if (sideTeam || sigAdmin || phySecMember) {
      dispatch(setUserSelectedSite(selectedSite));
      dispatch(setUserSelectedSitePrivs([Privileges.all]));
    } else {
      const userSelectedSitePrivs = await fetchUserSitePrivs(employeeId, username, selectedSite.sitename, 'MASKING APPROVERS');
      debug(`SelectSite(): siteSelected() userSelectedSitePrivs is ${JSON.stringify(userSelectedSitePrivs)}`);
      if (userSelectedSitePrivs.length === 0) {
        setSiteInvalidAlertVisible(true);
        return;
      } else {
        dispatch(setUserSelectedSite(selectedSite));
        dispatch(setUserSelectedSitePrivs(userSelectedSitePrivs));
      }
    }
    if (props.setSiteSelectVisibleCallback) props.setSiteSelectVisibleCallback(false);
    if (props.siteSelectedCallback) props.siteSelectedCallback(true);
  };

  const refreshUserSites = () => {
    debug('SelectSite(): refreshUserSites()')
    if (props.refreshUserSitesCallback) props.refreshUserSitesCallback();
  };

  const siteSelectRef = useRef<SelectProps.Ref>(null);

  useEffect(() => {
    debug(`SelectSite() useEffect()[userPrefs] userPrefs is ${JSON.stringify(userPrefs)}`);
    siteSelectRef.current?.focus();
    debug(`SelectSite() useEffect()[userPrefs] userSites is ${JSON.stringify(userSites)}`);
    if (userPrefs && userPrefs.lastSite && userSites.some(us => us.sitename === userPrefs.lastSite?.sitename)) {
      debug('SelectSite() useEffect()[userPrefs] setting selectedSiteName');
      setSelectedSiteName(userPrefs.lastSite.sitename);
    }
  }, [userPrefs]);

  if (isBundleLoading) return <Spinner />;

  return (
    <Container
      footer={
        <Grid
          gridDefinition={[
            { colspan: 12 },
          ]}
        >
          <Box float='right'>
            <SpaceBetween size='m' direction='horizontal'>
              <Button
                variant='primary'
                onClick={siteSelected}
                disabled={!selectedSiteName}
                ariaLabel={bundle.getMessage('select-site')}
              >
                {bundle.getMessage('select')}
              </Button>
              <Button
                variant='normal'
                onClick={refreshUserSites}
                ariaLabel={bundle.getMessage('refresh-sites')}
              >
                <div hidden={!props.loadingSites}>
                  <Spinner />
                </div>
                <div hidden={props.loadingSites}>
                  {bundle.getMessage('refresh-sites')}
                </div>
              </Button>
            </SpaceBetween>
          </Box>
        </Grid>
      }
      header={bundle.getMessage('choose-a-site')}
    >
      <SpaceBetween size='m' direction='vertical'>
        <FormField label={bundle.getMessage('site')}>
          <Select
            ref={siteSelectRef}
            filteringType='auto'
            loadingText={bundle.getMessage('loading-sites')}
            empty={bundle.getMessage('no-user-sites-found')}
            placeholder={bundle.getMessage('select-site')}
            filteringPlaceholder={bundle.getMessage('enter-text-to-search-sites')}
            onChange={({ detail }) => siteFieldOnChangeHandler(detail)}
            options={getSiteOptions()}
            ariaLabel={bundle.getMessage('user-sites')}
            selectedAriaLabel={bundle.getMessage('selected-user-site')}
            ariaDescribedby={selectedSiteName}
            renderHighlightedAriaLive={option => bundle.formatMessage('select-given-site', { siteName: option.label })}
            ariaRequired
            selectedOption={{ label: selectedSiteName, value: selectedSiteName }}
            statusType={props.loadingSites ? 'loading' : 'finished'}
          />
        </FormField>
        <Link href={URLS.SilencioFAQ} external target={'_blank'}>
          <b>{bundle.getMessage('not-seeing-sites')}</b>
        </Link>
        {siteRequiredAlertVisible &&
          <Alert
            dismissAriaLabel={bundle.getMessage('close-alert')}
            dismissible
            onDismiss={() => setSiteRequiredAlertVisible(false)}
            header={bundle.getMessage('selection-required')}
            type='warning'
          >
            {bundle.getMessage('selection-required-message')}
          </Alert>
        }
        {siteInvalidAlertVisible &&
          <Alert
            dismissAriaLabel={bundle.getMessage('close-alert')}
            dismissible
            onDismiss={() => setSiteInvalidAlertVisible(false)}
            header={bundle.getMessage('site-invalid')}
            type='warning'
          >
            {bundle.getMessage('site-invalid-message')}
          </Alert>
        }
      </SpaceBetween>
    </Container>
  );
}